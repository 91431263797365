import { t } from '@zupr/i18n'
import React from 'react'

import PlusIcon from '../../../../../svg/plus.svg'
import LinkChangePath from '../../../../shared/router/link-change-path'

import '../../../../../scss/react/backoffice/view-map.scss'

const ViewMap = ({ baseUrl, mapView = false }) => (
    <React.Fragment>
        {!mapView && (
            <div className="view-map">
                <LinkChangePath
                    className="btn btn-small"
                    href={`${baseUrl}/kaart`}
                >
                    <span>{t('Toon kaart')}</span>
                    <PlusIcon />
                </LinkChangePath>
            </div>
        )}

        {!!mapView && (
            <LinkChangePath
                className="btn btn-small btn-full-width"
                href={baseUrl}
            >
                <span>{t('Toon lijst')}</span>
            </LinkChangePath>
        )}
    </React.Fragment>
)

export default ViewMap
