import { t } from '@zupr/i18n'
import { useMemo } from 'react'

import { usePathname, useSearchParams } from '../../context/route'
import FilterCollapse from './collapse'
import RemoveFilter from './remove'
import SelectItem from './select-item'
import {
    FilterMultiselectConfiguration,
    MultiselectMultiOption,
    MultiselectSingleOption,
} from './types'

interface RemoveProps {
    options: FilterMultiselectConfiguration['options']
}

export const RemoveMultiselectSingleOptionFilter = ({
    option,
}: {
    option: MultiselectSingleOption
}) => {
    const searchParams = useSearchParams()

    const isChecked = useMemo(() => {
        const value = searchParams.get(option.filterKey)
        return value === option.checked.value
    }, [option.checked.value, option.filterKey, searchParams])

    if (!isChecked) return null

    return (
        <RemoveFilter filterKey={option.filterKey}>
            {t(option.label)}
            {option.checked.label && `: ${option.checked.label}`}
        </RemoveFilter>
    )
}

export const RemoveMultiselectMultiOptionFilter = ({
    option,
}: {
    option: MultiselectMultiOption
}) => {
    const pathname = usePathname()
    const searchParams = useSearchParams()

    const isChecked = useMemo(() => {
        return option.isChecked(searchParams)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    const href = useMemo(() => {
        let newSearchParams = new URLSearchParams(searchParams)

        if (isChecked) {
            newSearchParams = option.unCheck(newSearchParams)
        } else {
            newSearchParams = option.setChecked(newSearchParams)
        }
        return `${pathname}?${newSearchParams.toString()}`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked, pathname, searchParams])

    if (!isChecked) return null

    return <RemoveFilter href={href}>{t(option.label)}</RemoveFilter>
}

export const RemoveMultiselectFilter = ({ options }: RemoveProps) => {
    return (
        <>
            {options.map((option) => {
                if ('filterKey' in option) {
                    return (
                        <RemoveMultiselectSingleOptionFilter option={option} />
                    )
                }
                return <RemoveMultiselectMultiOptionFilter option={option} />
            })}
        </>
    )
}

interface Props extends Omit<FilterMultiselectConfiguration, 'type'> {
    type?: 'multiselect'
}

const MultiselectSingleOptionItem = ({
    option,
}: {
    option: MultiselectSingleOption
}) => {
    const pathname = usePathname()
    const searchParams = useSearchParams()

    const isChecked = useMemo(() => {
        const value = searchParams.get(option.filterKey)
        return value === option.checked.value
    }, [option.checked.value, option.filterKey, searchParams])

    const href = useMemo(() => {
        const newSearchParams = new URLSearchParams(searchParams)

        if (isChecked) {
            newSearchParams.delete(option.filterKey)
        } else {
            newSearchParams.set(option.filterKey, option.checked.value)
        }
        return `${pathname}?${newSearchParams.toString()}`
    }, [
        isChecked,
        option.checked.value,
        option.filterKey,
        pathname,
        searchParams,
    ])

    return (
        <SelectItem
            checked={isChecked}
            key={option.label}
            name={option.label}
            title={option.label}
            href={href}
        />
    )
}

const MultiselectMultiOptionItem = ({
    option,
}: {
    option: MultiselectMultiOption
}) => {
    const pathname = usePathname()
    const searchParams = useSearchParams()

    const isChecked = useMemo(() => {
        return option.isChecked(searchParams)
    }, [option, searchParams])

    const href = useMemo(() => {
        let newSearchParams = new URLSearchParams(searchParams)

        if (isChecked) {
            newSearchParams = option.unCheck(newSearchParams)
        } else {
            newSearchParams = option.setChecked(newSearchParams)
        }
        return `${pathname}?${newSearchParams.toString()}`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked, pathname, searchParams])

    return (
        <SelectItem
            checked={isChecked}
            key={option.label}
            name={option.label}
            title={option.label}
            href={href}
        />
    )
}

const MutltiselectFilter = ({ label, startCollapsed, options }: Props) => {
    return (
        <FilterCollapse header={label} startCollapsed={startCollapsed}>
            <div className="multiselect-filter">
                <ul>
                    {options?.map((option) => {
                        if ('filterKey' in option)
                            return (
                                <MultiselectSingleOptionItem option={option} />
                            )
                        return <MultiselectMultiOptionItem option={option} />
                    })}
                </ul>
            </div>
        </FilterCollapse>
    )
}

export default MutltiselectFilter
